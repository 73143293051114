import {
  HomePageToolkit,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import GiteaLogo from '../Root/GiteaLogo';

import { Header } from '@backstage/core-components';

import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid } from '@material-ui/core';
import React from 'react';

export const HomePage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Header title="Welcome to the intive Developer Platform!" />
            <Grid container item xs={12}>
              <Grid item xs={12} md={3}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={3}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://gitea.dev.idp.intive-projects.com',
                      label: 'Gitea',
                      icon: <GiteaLogo />,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
