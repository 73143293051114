import {
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const blue = '#007ac9';
const pink = '#e21776';
const softPink = '#f8a1c9';
const white = '#ffffff';
const gray = '#EFEFEF';

export const intiveTheme = createUnifiedTheme({
  palette: {
    ...palettes.light, // based on light theme
    primary: {
      main: blue, // light blue
    },
    secondary: {
      main: pink, // Darker blue grey
    },
    error: {
      main: softPink,
    },
    warning: {
      main: pink,
    },
    info: {
      main: blue,
    },
    success: {
      main: '#35abe2',
    },
    background: {
      default: '#efefef',
      paper: '#f4f4f4', // Light grey
    },
    banner: {
      info: '#35abe2', // light blue banner
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      submenu: {
        background: '#35abe2', // light blue
      },
      background: white, // light blue
      indicator: '#9d599f', // lilac as indicator
      selectedColor: blue, // same as indicator
      color: '#000000', // dark blue for unselected items
      navItem: {
        hoverBackground: gray, // light blue
      },
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: [blue], shape: 'none' }),
    documentation: genPageTheme({
      colors: [blue],
      shape: 'none',
    }),
    project: genPageTheme({
      colors: [blue],
      shape: 'none',
    }),
    tool: genPageTheme({
      colors: [blue],
      shape: 'none',
    }),
    library: genPageTheme({
      colors: [blue],
      shape: 'none',
    }),
    technique: genPageTheme({ colors: [blue], shape: 'none' }),
    other: genPageTheme({ colors: [blue ], shape: 'none' }),
    apis: genPageTheme({ colors: [blue], shape: 'none' }),
  },
  fontFamily: 'Arial, sans-serif',
  components: {
    BackstageInfoCard: {
      styleOverrides: {},
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecorationLine: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Remove uppercase text
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#35abe2', // light blue on hover
          },
          color: '#FFFFFF',
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#35abe2', // light blue on hover
          },
          color: '#FFFFFF',
        },
      },
    },
  },
});
